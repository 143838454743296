import * as React from "react"

import HomeLayout from "../components/HomeLayout"
import SEO from "../components/SEO"
import { Center, Flex, Heading, Text } from "@chakra-ui/react"

const NotFoundPage = () => (
  <HomeLayout pageTitle={"Oops!"}>
    <SEO title="404: Not found" />
    <Flex justifyContent={"center"} flexDirection={"column"} mt={4}>
      <Center>
        <Heading size={"md"} mb={2}>
          Not Found (http/404)
        </Heading>
      </Center>
      <Text as={"cite"}>These are not the droids you are looking for!</Text>
    </Flex>
  </HomeLayout>
)

export default NotFoundPage
